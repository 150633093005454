.confirm-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .confirm-popup {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    width: 320px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  .confirm-popup p {
    margin: 0;
    font-size: 16px;
    color: #333;
  }
  .confirm-popup-buttons {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
  }
  .confirm-popup-confirm,
  .confirm-popup-cancel {
    flex: 1;
    margin: 0 5px;
    border: none;
    padding: 10px 0;
    border-radius: 6px;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .confirm-popup-confirm {
    background-color: #e74c3c;
    color: #fff;
  }
  .confirm-popup-confirm:hover {
    background-color: #c0392b;
  }
  .confirm-popup-cancel {
    background-color: #95a5a6;
    color: #fff;
  }
  .confirm-popup-cancel:hover {
    background-color: #7f8c8d;
  }
  