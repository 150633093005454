.forgot-password-container {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .forgot-password-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .forgot-password-container .form-group {
    margin-bottom: 15px;
  }
  
  .forgot-password-container .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .forgot-password-container .form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .forgot-password-container .forgot-password-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #0c2746;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .forgot-password-container .forgot-password-button:hover {
    background-color: #0c2746;
  }
  