.status-table-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 800px;
  }
  
  .status-table-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .status-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .status-table th,
  .status-table td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    text-align: center;
  }
  
  .status-table th {
    background-color: #f2f2f2;
  }
  
  .status-icon {
    font-size: 1.5em;
  }
  
  .status-icon.green {
    color: green;
  }
  
  .status-icon.red {
    color: red;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .status-table-container {
      margin-left: 30px;
      
    }
  }
  