.login-container {
    background-image: url('../assets/fundo-home2.jpeg');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 97vh;
    padding: 20px;
  }
  
.logo {
    width: 300px; 
    margin-bottom: 20px;
    opacity: 0.7;
  }
  
.login-form {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    height: 330px;
  }
  
.login-form h2 {
    margin-top: 30px;
    margin-bottom: 20px;
    color: black;
  }
  
.form-group {
    margin-bottom: 15px;
  }
  
.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #0c2746;
  }
  
.form-group input {
    margin-top: 10px;
    width: 100%;
    height: 40px;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
.login-button {
    
    width: 100%;
    padding: 10px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
  }
  
.login-button:hover {
    background-color: #0c2746;
  }
  
.error {
    color: red;
    
  }
  .forgot-password-link {
    margin-top: 10px;
    color: #0c2746;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .forgot-password-link:hover {
    color: #0c2746;
  }
  