.register-container {
  max-width: 450px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .register-container {
    max-width: 300px;
    margin-left: 60px;
    margin-top: 0px;
  }
}

.register-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.register-form .form-group {
  margin-bottom: 15px;
}

.register-form .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.register-form .form-group input,
.register-form .form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.register-form .register-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #0c2746;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.register-form .register-button:hover {
  background-color: #1e90ff;
}
.register-form .register-button:disabled {
  background-color: grey;
  cursor: not-allowed;
}
.register-form .register-button:disabled:hover {
  background-color: grey;
  cursor: not-allowed;
}

.user-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.user-table th,
.user-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.user-table th {
  background-color: #0c2746;
  color: white;
}

.user-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.user-table tr:hover {
  background-color: #f1f1f1;
}

.activate-button,
.deactivate-button,
.delete-button,
.edit-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
}

.activate-button {
  background-color: green;
}

.activate-button:hover {
  background-color: darkgreen;
}

.deactivate-button {
  background-color: grey;
}

.deactivate-button:hover {
  background-color: darkgrey;
}

.delete-button {
  background-color: red;
  margin-left: 5px;
}

.delete-button:hover {
  background-color: darkred;
}

.edit-button {
  background-color: blue;
  margin-left: 5px;
}

.edit-button:hover {
  background-color: darkblue;
}

.edit-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
}

.edit-popup-content h2 {
  text-align: center;
  margin-bottom: 20px;
}

.edit-popup-content .form-group {
  margin-bottom: 15px;
}

.edit-popup-content .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.edit-popup-content .form-group input,
.edit-popup-content .form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.update-button,
.cancel-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.update-button {
  background-color: #0c2746;
}

.update-button:hover {
  background-color: #1e90ff;
}

.cancel-button {
  background-color: grey;
}

.cancel-button:hover {
  background-color: darkgrey;
}
