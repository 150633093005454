@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.auth-container {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}
.auth-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; /* Ajusta o tamanho da imagem para caber no container */
  height: auto; /* Mantém a proporção da imagem */
  margin-bottom: 20px; /* Espaçamento abaixo da imagem */
}
@media (max-width: 600px) {
    .auth-container {
      max-width: 300px;
      margin-left: 55px;
      margin-top: 20px;
    }
  }
.auth-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #0c2746;
}

.auth-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.auth-table th,
.auth-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.auth-table th {
  background-color: #0c2746;
  color: white;
}

.auth-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.auth-table tr:hover {
  background-color: #f1f1f1;
}

.activate-button,
.deactivate-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
}

.activate-button {
  background-color: green;
}

.activate-button:hover {
  background-color: darkgreen;
}

.deactivate-button {
  background-color: grey;
}

.deactivate-button:hover {
  background-color: darkgrey;
}
