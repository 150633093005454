/* Estilos gerais para o container dos componentes */
.mensagens-padrao-container,
.servicos-padrao-container {
    padding: 20px;
    margin: 20px auto;
    max-width: 800px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Título centralizado */
.mensagens-padrao-container h2,
.servicos-padrao-container h2 {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: #333;
}

/* Estilo para lista de itens */
.mensagens-padrao-container ul,
.servicos-padrao-container ul {
    list-style-type: none;
    padding: 0;
}

.mensagens-padrao-container li,
.servicos-padrao-container li {
    background-color: #f9f9f9;
    margin: 5px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

/* Estilo para inputs e textarea */
.mensagens-padrao-container input[type="text"],
.servicos-padrao-container input[type="text"],
.servicos-padrao-container input[type="number"],
.mensagens-padrao-container textarea {
    width: calc(100% - 22px); /* Subtrai a margem */
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
}

/* Botão de adição */
.mensagens-padrao-container button,
.servicos-padrao-container button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.mensagens-padrao-container button:hover,
.servicos-padrao-container button:hover {
    background-color: #0056b3;
}

/* Responsividade para dispositivos móveis */
@media (max-width: 768px) {
    .mensagens-padrao-container,
    .servicos-padrao-container {
        padding: 15px;
        margin-left: 30px;
        width: 90%;
        
    }

    .mensagens-padrao-container input[type="text"],
    .servicos-padrao-container input[type="text"],
    .servicos-padrao-container input[type="number"],
    .mensagens-padrao-container textarea {
        width: calc(80% - 10px);
    }

    .mensagens-padrao-container button,
    .servicos-padrao-container button {
        width: 100%;
    }
}
