.manage-services-container {
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 600px) {
  .manage-services-container {
      margin-left: 120px;
  }
}

.manage-services-container h2 {
  margin-bottom: 20px;
  color: #333;
}

.manage-services-container .user-select {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 18px; /* Aumentar o tamanho da fonte */
}

.services-table {
  width: 100%;
  border-collapse: collapse;
}

.services-table th,
.services-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.services-table th {
  background-color: #0c2746;
  color: white;
}

.services-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.services-table tr:hover {
  background-color: #f1f1f1;
}

/* Aplicando estilos globais para botões dentro do container */
.manage-services-container .block-button,
.manage-services-container .unblock-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
}

.manage-services-container .block-button {
  background-color: red;
}

.manage-services-container .block-button:hover {
  background-color: darkred;
}

.manage-services-container .unblock-button {
  background-color: green;
}

.manage-services-container .unblock-button:hover {
  background-color: darkgreen;
}
