.edit-campanha-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .edit-campanha-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .edit-campanha-form .form-group {
    margin-bottom: 15px;
  }
  
  .edit-campanha-form .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .edit-campanha-form .form-group input,
  .edit-campanha-form .form-group textarea,
  .edit-campanha-form .form-group select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .edit-campanha-form .form-group input[type="file"] {
    padding: 3px;
  }
  
  .edit-campanha-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #0c2746;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .edit-campanha-button:hover {
    background-color: #1e90ff;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ff0000;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
  }
  
  .close-button:hover {
    background: #cc0000;
  }
  