/* Estilos gerais para o dashboard */
.dashboard-container {
    padding: 20px;
    margin-right: 20px; 
    max-width: 60%;
    margin-left: auto; 
    margin-right: auto; 
}
@media (max-width: 700px) {
    .dashboard-container {
        max-width: 85%;
    }
}

.chart-container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
}

.chart-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333; /* Cor do texto */
}

.chart-container select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-sizing: border-box;
}

.chart-container label {
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}

.chart-container .apexcharts-canvas {
    max-width: 100%;
}
.feedbacks-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.feedbacks-table th,
.feedbacks-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.feedbacks-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.feedbacks-table td {
    background-color: #fff;
}

.feedbacks-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Responsividade */
@media (max-width: 768px) {
    .dashboard-container {
        padding: 10px;
        margin-right: 10px;
    }

    .chart-container {
        padding: 15px;
    }

    .chart-container h2 {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .chart-container {
        padding: 10px;
    }

    .chart-container h2 {
        font-size: 16px;
    }
}
