@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Popup Styles */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.celular-image {
  position: relative;
  width: 800px;
  height: 800px;
  background-size: contain;
  background-repeat: no-repeat;
}

.message-box {
  position: absolute;
  top: 110px; 
  left: 20px; 
  width: 270px; 
  height: 200px; /* Altura padrão */
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: height 0.3s; /* Adiciona transição suave */
}

.popup-image {
  position: absolute;
  width: 200px;
  height: 160px;
  left: 280px;
  top: 70px;
  z-index: 3;
}

.popmsg-container {
  position: absolute;
  z-index: 3;
  left: 70px;
  top: 150px;
  max-width: 250px; /* Mantém a largura máxima */
  max-height: 250px; /* Altura máxima com rolagem */
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow-y: auto; /* Adiciona rolagem vertical */
}

.popmsg {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.popmsg-container::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem */
}

.popmsg-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor do track da barra de rolagem */
  border-radius: 10px; /* Borda do track da barra de rolagem */
}

.popmsg-container::-webkit-scrollbar-thumb {
  background: #006400; /* Cor do thumb da barra de rolagem */
  border-radius: 10px; /* Borda do thumb da barra de rolagem */
  border: 2px solid #f1f1f1; /* Borda do thumb */
}

.popmsg-container::-webkit-scrollbar-thumb:hover {
  background: #004d00; /* Cor do thumb da barra de rolagem ao passar o mouse */
}

.message-content {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  position: absolute;
  top: 100px;
  left: 180px;
  word-wrap: break-word;
}

.chat-image {
  position: absolute;
  top: -80px;
  width: 280px;
  height: 430px;
  left: 50px;
}

.chat-image-200 {
  height: 390px; /* Altura padrão */
}
.chat-image-250 {
    height: 420px;
}
.chat-image-240 {
  height: 520px; /* Aumenta a altura em 40px */
}
.chat-image-50 {
    height: 330px; /* Aumenta a altura em 40px */
  }
  .chat-image-30 {
    height: 330px
  }
  .chat-image-15 {
    height: 285px
  }
  .chat-image-100 {
    height: 350px; /* Aumenta a altura em 40px */
  }
  .chat-image-360 {
    height: 485px;
  }
  .chat-image-150 {
    height: 370px; /* Aumenta a altura em 40px */
  }
  .chat-image-300 {
    height: 455px;
  }
  .chat-image-400 {
    height: 520px;
  }

.close-button {
  position: absolute;
  z-index: 999;
  top: -20px;
  right: 10px;
  background: #ff0000;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
}

.close-button:hover {
  background: #cc0000;
}
