.header-user-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 80px; /* Largura colapsada */
  background-color: #0c2746; /* Cor de fundo */
  color: white;
  transition: width 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.header-user-container.expanded {
  width: 200px; /* Largura expandida */
}

.header-user-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0px;
  width: 100%;
}

.header-user-container.collapsed {
  width: 50px;
}

.header-user-logo {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  transition: opacity 0.3s;
}

.header-user-container.collapsed .header-user-logo {
  opacity: 0;
}

.header-user-nav-button {
  display: flex;
  align-items: center;
  margin: 1px 0;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  transition: background-color 0.2s;
}

.header-user-nav-button img.nav-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.header-user-nav-button:hover {
  background-color: #0c317c;
}

.header-user-nav-button span {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.3s;
}

.header-user-container.expanded .header-user-nav-button span {
  opacity: 1;
}

.header-user-footer {
  margin-top: auto;
  padding: 10px;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column; /* Organiza os itens em coluna */
  align-items: center;    /* Centraliza os itens */
}

.footer-title {
  font-weight: bold;
  display: block;
  font-size: 25px;
  margin-top: 30px;
}

.footer-username {
  font-size: 20px;
  display: block;
}

.header-user-container.collapsed .header-user-footer {
  opacity: 0;
}

/* Estilos da foto e nome do robô */
.footer-robo-foto {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.footer-robo-nome {
  font-size: 18px;
  margin-bottom: 10px;
}
@media (max-height: 920px) {
  .footer-title {
    display: none;
  }
}

