.manage-parametros-container {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
  }
  .param-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 50%; /* Ajusta o tamanho da imagem para caber no container */
    height: auto; /* Mantém a proporção da imagem */
    margin-bottom: 20px; /* Espaçamento abaixo da imagem */
  }
  
  @media (max-width: 600px) {
    .manage-parametros-container {
      max-width: 400px;
      margin-left: 25px;
    }
  }
  
  .manage-parametros-container h2 {
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .parametros-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .parametro-item {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .parametro-item h3 {
    margin: 0 0 10px;
    color: #0c2746;
  }
  
  .parametro-content p {
    margin: 0 0 10px;
  }
  
  .edit-area {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .edit-area textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .edit-button,
  .save-button,
  .cancel-button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    background-color: #0c2746;
  }
  
  .edit-button {
    background-color: #0c2746;
  }
  
  .edit-button:hover {
    background-color: #0c2746;
  }
  
  .save-button {
    background-color: green;
  }
  
  .save-button:hover {
    background-color: darkgreen;
  }
  
  .cancel-button {
    background-color: red;
  }
  
  .cancel-button:hover {
    background-color: darkred;
  }
  .upload-foto {
    margin-top: 20px;
    padding: 10px;
    max-width: 800px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.upload-foto h3 {
    margin-top: 5px;
    margin-bottom: 20px;
    color: #333;
}

.upload-input {
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    max-width: 400px;
    display: block; /* Garante que o campo input fique em uma linha separada */
}

.upload-buttonzz {
    padding: 10px 20px;
    background-color: #0c2746;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block; /* Garante que o botão fique em uma linha separada */
    margin-top: 10px; /* Adiciona espaço entre o botão e o campo de input */
}

.upload-buttonzz:hover {
    background-color: #0f4183;
}

.upload-preview {
    margin-top: 15px;
    display: block;
    max-width: 200px;
    max-height: 200px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
    .upload-foto {
        max-width: 400px;
        margin-left: 25px;
    }

    .upload-preview {
        max-width: 150px;
        max-height: 150px;
    }
}

  