.numeros-campanha-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .cnumb-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 50%; /* Ajusta o tamanho da imagem para caber no container */
    height: auto; /* Mantém a proporção da imagem */
    margin-bottom: 20px; /* Espaçamento abaixo da imagem */
  }
  .aviso-numeros-campanha {
    text-align: center;
    color: red;
  }
  h1, h2 {
    text-align: center;
    color:  #0c2746;
  }
  
  .form, .file-upload-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  .form-group input, .form-group select {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .submit-button, .upload-button, .save-button, .cancel-button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color:  #0c2746;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover, .upload-button:hover, .save-button:hover {
    background-color:  #0c2746;
  }
  .download-xls-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #0c2746;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .download-xls-button:hover {
    background-color: #2432af;
  }
  .cancel-button {
    background-color: grey;
  }
  
  .cancel-button:hover {
    background-color: darkgrey;
  }
  
  .table-container {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .table th, .table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .table th {
    background-color:  #0c2746;
    color: white;
  }
  
  .table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .edit-button, .delete-button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    margin-right: 5px;
  }
  
  .edit-button {
    background-color: blue;
  }
  
  .edit-button:hover {
    background-color: darkblue;
  }
  
  .delete-button {
    background-color: red;
  }
  
  .delete-button:hover {
    background-color: darkred;
  }
  
  /* Popup de Edição */
  .edit-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .edit-popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .edit-popup-content h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .numeros-campanha-container {
      padding: 10px;
      width: 80%;
      margin-left: 50px;
    }
  
    .edit-popup-content {
      width: 90%;
    }
  }
  