.servicos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh; /* Para centralizar verticalmente */
  }
  .servicos-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%; /* Ajusta o tamanho da imagem para caber no container */
    height: auto; /* Mantém a proporção da imagem */
    margin-bottom: 20px; /* Espaçamento abaixo da imagem */
  }
  .table-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .table {
    width: 80%;
    max-width: 1000px; /* Define a largura máxima da tabela */
    border-collapse: collapse;
    overflow-x: auto; /* Permite rolagem horizontal se necessário */
  }
  
  @media (max-width: 600px) {
    .table {
      max-width: 80%; /* Ajusta a largura máxima para telas pequenas */
      margin-left: 50px;
    }
  }
  .sim {
    color: green;
  }
  
  .nao {
    color: red;
  }
  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    word-wrap: break-word; /* Quebra palavras longas */
  }
  
  .table th {
    background-color: #0c2746;
    color: white;
  }
  
  .table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table tr:hover {
    background-color: #ddd;
  }
  
  .table th {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  
  .table td {
    padding: 8px;
  }
  