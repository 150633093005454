@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
.campanha-container {
  max-width: 900px;
  margin: 0 auto;
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}
.campanhas-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}
@media (max-width: 600px) {
  .campanha-container {
    max-width: 300px;
    margin-left: 55px;
    margin-top: 20px;
  }
}
.campanha-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #0c2746;
}
.campanha-form .form-group {
  margin-bottom: 15px;
}
.campanha-form .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #0c2746;
}
.campanha-form .form-group input,
.campanha-form .form-group textarea,
.campanha-form .form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.campanha-form .form-group input[type="file"] {
  padding: 3px;
}
.campanha-form .campanha-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #0c2746;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.campanha-form .campanha-button:disabled {
  background-color: grey;
  cursor: not-allowed;
}
.campanha-form .campanha-button:hover {
  background-color: #1e90ff;
}
.campanha-form .campanha-button:disabled:hover {
  background-color: grey;
}
.campanha-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}
.campanha-table th,
.campanha-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  vertical-align: top;
}
.campanha-table th {
  background-color: #0c2746;
  color: white;
}
.campanha-table tr:nth-child(even) {
  background-color: #f9f9f9;
}
.campanha-table tr:hover {
  background-color: #f1f1f1;
}
.campanha-table td {
  word-break: break-word;
  white-space: pre-wrap;
}
.campanha-table .image-column {
  width: 120px;
}
.view-button22 {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  background-color: blue;
  margin-right: 5px;
}
.activate-button,
.deactivate-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
}
.activate-button {
  background-color: green;
}
.activate-button:hover {
  background-color: darkgreen;
}
.deactivate-button {
  background-color: grey;
}
.deactivate-button:hover {
  background-color: darkgrey;
}
.edit-button {
  margin-left: 5px;
  background-color: #0c2746;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
}
.edit-button:hover {
  background-color: #1e90ff;
}
.delete-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  background-color: red;
  margin-left: 5px;
}
.delete-button:hover {
  background-color: darkred;
}
