@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.home-container {
  width: 100%;
  height: 1080px;
  display: flex;
  flex-direction: column;
  font-family: 'Bungee', cursive;
  box-sizing: border-box; /* Garante que padding e border são incluídos nas dimensões */
  overflow-x: hidden; /* Remove o scroll lateral */
  background-image: url('../assets/fundo-home2.jpeg');
}

.home-header {
  width: 100%;
  height: 100px; /* Aumentando a altura do header */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px; /* Ajustando o padding para espaço */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Garante que padding e border são incluídos nas dimensões */
  overflow: hidden; /* Garante que nada dentro do header cause scroll */
}

.home-logo {
  height: 200px; /* Aumentando a altura da logo */
  width: 200px;
  top:0px;
  opacity: 0.6;
  position: absolute;
}

.home-nav {
  display: flex;
  align-items: center;
  gap: 30px; /* Espaçamento entre os links */
  flex-grow: 1;
  justify-content: flex-end;
}

.home-nav-link {
  color: white;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s;
}

.home-nav-link:hover {
  color: #1e90ff;
}

.home-client-area-link {
  background-color: rgb(146, 1, 1);
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 20px;
  border: 2px solid darkred;
  font-size: 18px;
  transition: background-color 0.3s;
}

.home-client-area-link:hover {
  background-color: darkred;
}

.home-content {
  margin-bottom: 100px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.home-content-image {
  max-width: 45%;
  height: auto;
  margin: 0 20px; /* Espaçamento entre as imagens e o texto */
}

.descricao-texto {
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  color: white;
  text-align: center;
  font-weight: 700;
  line-height: 1.5;
}

.descricao-container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; /* Centraliza o texto */
  }

.robo-img {
  width: 200px;
}

.mao-apontando {
  position: absolute;
  top: -5%;
  right: 12%;
  max-width: 6%;
}

@media (max-width: 900px) {
    .home-logo {
        width: 30%;
        height: 80px;
        top: 10px;
    }
    .pc-image{
        position: absolute;
        max-width: 400px;
        top: 600px;
    }
    .home-nav-link:not(.home-client-area-link) {
        display: none;
      }
    .descricao-texto {
        font-size: 10px;
        margin-right: 120px;
        margin-bottom: 20px;
        text-align: match-parent;
    }
    .infinito-image{ 
        width: 200px;
        position: absolute;
        top: 400px;
        left: calc(50% - 100px);
    }
    .descricao-container {
        font-size: 10px;
    }
    .home-content {
        margin-bottom: 500px;
    }
    .mao-apontando {
        display: none;
    }

    }
   
@media (max-width: 1600px) {
  .mao-apontando {
    right: 15%;
  }
}

@media (max-width: 1200px) {
  .mao-apontando {
    right: 18%;
    
  }
  .descricao-texto {
    font-size: 25px;
  }
  
}

.info-section {
  
    
  
}
.especificacoes {
    width: 100%;
    
    
}
.all-home {
    margin: 0;
    background-image: url('../assets/fundo-home2.jpeg');
  padding: 0;
}

.home-footer {
    color: white;
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative; /* Adicionando position relative */
  }
  
  .footer-column {
    margin: 20px;
  }
  
  .footer-logo {
    width: 120px;
    opacity: 5.1;
  }
  
  .social-icons img,
  .contact-icons img {
    width: 30px;
    margin: 5px;
  }
  
  .contact-icons {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centralizando os ícones de contato */
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .contact-item span {
    margin-left: 10px;
  }
  
  .footer-copy {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  
  @media (min-width: 768px) {
    .home-footer {
      flex-direction: row;
      justify-content: space-around;
    }
  
    .footer-copy {
      flex-basis: 100%;
      text-align: center;
      margin-top: 20px;
    }
    
    
  }
.infinito-image{
    width: 200px;
    margin-top: 0px;
}


.social-icons{
    transition: background-color 0.3s;
} 
.clientes-container {
  text-align: center;
  padding: 20px;
}

.clientes-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 33px;
  color: #eeebeb;
  margin-bottom: 20px;
}

.logos-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Espaço entre as logos */
}

.cliente-logo {
  width: 300px; /* Ajuste o tamanho conforme necessário */
  height: auto;
}
@media (max-width: 768px) {
  .logos-container {
    flex-direction: column;
  }

  .cliente-logo {
    width: 200px; /* Reduz o tamanho das logos para dispositivos menores */
  }
  .clientes-title {
    
    font-size: 25px;
    
  }
}