.analytics-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.period-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.period-selection label {
  font-size: 1.2em;
  color: #0c2746;
}

.period-selection select {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px;
  margin-top: 10px;
}

.status-selection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.status-selection label {
  font-size: 1.2em;
  color: #0c2746;
  margin-bottom: 10px;
}

.status-selection select {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px;
}

.charts-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.line-chart, .pie-chart {
  width: 48%;
}

.info-boxes-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}

.info-box {
  width: 38%;
  padding: 20px;
  text-align: center;
}

.atendimentos-table {
  width: 80%;
  max-width: 1000px;
  border-collapse: collapse;
  margin: 20px auto;
}

.atendimentos-table th,
.atendimentos-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  word-wrap: break-word;
}

.atendimentos-table th {
  background-color: #0c2746;
  color: white;
  padding-top: 12px;
  padding-bottom: 12px;
}

.atendimentos-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.atendimentos-table tr:hover {
  background-color: #ddd;
}

.atendimentos-table td {
  padding: 8px;
}

@media (max-width: 768px) {
  .line-chart, .pie-chart, .info-box {
      width: 100%;
      margin-bottom: 20px;
  }
}
