@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.disparo-container {
  max-width: 700px;
  margin: 0 auto;
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}
.disparos-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 70%; /* Ajusta o tamanho da imagem para caber no container */
  height: auto; /* Mantém a proporção da imagem */
  margin-bottom: 20px; /* Espaçamento abaixo da imagem */
}


.disparo-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #0c2746;
}
.contatos-list {
  list-style-type: none;
  padding: 0;
  margin: 0; /* Remove qualquer margem lateral */
}

.contato-item {
  display: flex;
  align-items: center;
  gap: 10px; /* Espaçamento entre os elementos */
}

.contato-item input[type="checkbox"] {
  width: 20px; /* Define o tamanho do checkbox */
  height: 20px;
  margin-left: -300px; /* Mantém o ajuste que você mencionou */
}

.contato-nome {
  min-width: 150px; /* Define um tamanho mínimo para o nome */
  margin-left: -320px; /* Mantém o ajuste que você mencionou */
  white-space: nowrap; /* Impede que o texto quebre de linha */
  overflow: hidden; /* Evita que o texto longo quebre o layout */
  text-overflow: ellipsis; /* Adiciona "..." para nomes muito longos */
}

.contato-numero {
  
}
@media (max-width: 600px) {
  .disparo-container {
    max-width: 300px;
    margin-left: 55px;
    margin-top: 20px;
  }
  .contato-item {
    flex-direction: column; /* Alinha os elementos em uma coluna */
    align-items: flex-start; /* Alinha os itens ao início (esquerda) */
    gap: 5px; /* Reduz o espaçamento entre os itens */
  }

  .contato-item input[type="checkbox"] {
    margin-left: 0; /* Reseta o margin-left para evitar comportamento inesperado no mobile */
  }

  .contato-nome {
    margin-left: 0; /* Remove o margin-left negativo no mobile */
    min-width: unset; /* Remove o tamanho mínimo no mobile */
    white-space: normal; /* Permite que o nome quebre em várias linhas se for necessário */
  }

  .contato-numero {
    margin-left: 0; /* Remove o alinhamento à direita no mobile */
  }
}
.disparo-form .form-group {
  margin-bottom: 15px;
}

.disparo-form .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #0c2746;
}

.disparo-form .form-group input,
.disparo-form .form-group textarea,
.disparo-form .form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.disparo-form .disparo-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #0c2746;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.disparo-form .disparo-button:hover {
  background-color: #1e90ff;
}

.disparo-table-container {
  overflow-x: auto;
}

.disparo-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  min-width: 600px; 
}

.disparo-table th,
.disparo-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.disparo-table th {
  background-color: #0c2746;
  color: white;
}

.disparo-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.disparo-table tr:hover {
  background-color: #f1f1f1;
}

.activate-button,
.deactivate-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
}

.activate-button {
  background-color: green;
}

.activate-button:hover {
  background-color: darkgreen;
}

.deactivate-button {
  background-color: grey;
}

.deactivate-button:hover {
  background-color: darkgrey;
}
