.seletores-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 800px;
}

.seletores-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.versionamentos-table, .seletores-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    table-layout: fixed; /* Evita que as colunas sejam redimensionadas automaticamente */
}

.versionamentos-table th,
.versionamentos-table td,
.seletores-table th,
.seletores-table td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    word-wrap: break-word; /* Permite que o conteúdo quebre em várias linhas se necessário */
}

.versionamentos-table th,
.seletores-table th {
    background-color: #f2f2f2;
}

.seletores-table td.key-column,
.seletores-table th.key-column {
    width: 15%; /* Reduz o espaço para a coluna de "key" */
}

.seletores-table td.actions-column,
.seletores-table th.actions-column {
    width: 10%; /* Reduz o espaço para a coluna de "ações" */
}

.seletores-table td.value-column,
.seletores-table th.value-column {
    width: 75%; /* Aumenta o espaço para a coluna de "valor" */
}

.edit-button,
.save-button,
.cancel-button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
    color: #fff;
    font-size: 14px; /* Reduzido o tamanho da fonte para melhor ajuste */
}

.edit-button {
    background-color: #0f3358;
}

.save-button {
    background-color: #28a745;
}

.cancel-button {
    background-color: #dc3545;
}

.edit-button:hover,
.save-button:hover,
.cancel-button:hover {
    opacity: 0.8;
}

input[type="text"] {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

.section-spacing {
    margin-top: 40px; /* Espaçamento adicional entre as seções */
}

@media (max-width: 900px) {
    .seletores-container {
       
       margin-left: 30px;
    }

    .seletores-table td.actions-column,
    .seletores-table th.actions-column {
        width: 20%; /* Aumenta a largura da coluna de ações */
    }

    .seletores-table td.value-column,
    .seletores-table th.value-column {
        width: 65%; /* Reduz o espaço da coluna de valor para dar mais espaço às ações */
    }

    .edit-button,
    .save-button,
    .cancel-button {
        font-size: 12px; /* Reduz o tamanho da fonte para os botões */
        padding: 6px 10px; /* Ajusta o padding para botões menores */
        margin-bottom: 5px; /* Adiciona espaçamento entre os botões empilhados */
    }
    
    .seletores-table td.key-column,
    .seletores-table th.key-column,
    .seletores-table td.actions-column,
    .seletores-table th.actions-column {
        width: auto; /* Ajusta a largura para dispositivos móveis */
    }

    .seletores-table td.value-column,
    .seletores-table th.value-column {
        width: 80%; /* Ajusta a largura para dispositivos móveis */
    }

    .seletores-table td,
    .seletores-table th {
        font-size: 12px; /* Reduz o tamanho da fonte para se ajustar a telas menores */
    }

    .seletores-table td.actions-column {
        text-align: center; /* Centraliza os botões */
    }

    .seletores-table tr {
        display: flex;
        flex-direction: column; /* Faz com que as linhas se comportem como colunas em telas menores */
    }
}
