.update-container {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 100px;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  .update-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .update-form-group {
    display: flex;
    flex-direction: column;
  }
  
  .update-form-group label {
    margin-bottom: 5px;
    color: #555;
  }
  
  .update-form-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .update-submit-button {
    padding: 10px 15px;
    background-color: #0c2746;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .update-submit-button:hover {
    background-color: #081a2e;
  }
  
  .update-toggle-password-form-button {
    padding: 10px 15px;
    background-color: #0c2746;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .update-toggle-password-form-button:hover {
    background-color: rgb(7, 24, 68);
  }
  
  @media (max-width: 600px) {
    .update-container {
      width: 300px;
      padding: 10px;
      margin-top: 0px;
      margin-left: 60px;
      
    }
  
    .update-form-group input {
      padding: 8px;
    }
  
    .update-submit-button, .update-toggle-password-form-button {
      padding: 8px 12px;
      font-size: 14px;
    }
  }
  