.container {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 100px;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  .atendimento-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%; /* Ajusta o tamanho da imagem para caber no container */
    height: auto; /* Mantém a proporção da imagem */
    margin-bottom: 20px; /* Espaçamento abaixo da imagem */
}
  
  h1, h2 {
    text-align: center;
    color: #333;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 5px;
    color: #555;
  }
  
  .form-group input,
  .form-group select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-button {
    padding: 10px 15px;
    background-color:  #0c2746;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color:  #05277c;
  }
  
  .table-container {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .table th {
    background-color: #0c2746;
    color: white;
  }
  
  .table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .table tr:hover {
    background-color: #ddd;
  }
  
  .delete-button {
    padding: 5px 10px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  
  .delete-button:hover {
    background-color: #e53935;
  }
  
  /* Responsividade */
  @media (max-width: 600px) {
    .container {
      padding: 10px;
      max-width: 80%;
      margin-left: 50px;
      margin-top: 0px;
    }
  
    .form-group {
      flex-direction: column;
    }
  
    .form-group label,
    .form-group input,
    .form-group select {
      width: 100%;
    }
  
    .table th, .table td {
      font-size: 14px;
      padding: 6px;
    }
  
    .submit-button {
      padding: 8px 12px;
      font-size: 14px;
    }
  
    .delete-button {
      padding: 4px 8px;
      font-size: 14px;
    }
  }
  .edit-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .edit-popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .edit-popup-content h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .edit-popup-content .form-group {
    margin-bottom: 15px;
  }
  
  .edit-popup-content .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .edit-popup-content .form-group input,
  .edit-popup-content .form-group select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .update-button,
  .cancel-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
  }
  
  .update-button {
    background-color: #0c2746;
  }
  
  .update-button:hover {
    background-color: #1e90ff;
  }
  
  .cancel-button {
    background-color: grey;
  }
  
  .cancel-button:hover {
    background-color: darkgrey;
  }
  .visualizacao-container {
    margin-top: 40px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .visualizacao-container h3 {
    margin-bottom: 10px;
    text-align: center;
  }
  
  .visualizacao-container .form-group {
    display: flex;
    align-items: center;
  }
  
  .visualizacao-container input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .visualizacao-container label {
    font-size: 16px;
    cursor: pointer;
  }
  .pre-atendimento-container {
    margin-top: 40px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .pre-atendimento-container h3 {
    margin-bottom: 10px;
    text-align: center;
  }
  
  .pre-atendimento-container .form-group {
    display: flex;
    align-items: center;
  }
  
  .pre-atendimento-container input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .pre-atendimento-container label {
    font-size: 16px;
    cursor: pointer;
  }

  .pre-atendimento-container {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .pre-atendimento-container p {
    color:red;
  }
  .analytics-atendente {
    max-width: 400px;
    margin: 30px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .analytics-atendente label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .analytics-atendente select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .dashboard-atendente {
    max-width: 1200px;
    margin: 0px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 1300px) {
    .container {
      max-width: 80%;
    }
  }
  
  @media (max-width: 600px) {
    .analytics-atendente, .dashboard-atendente {
      max-width: 80%;
      padding: 10px;
      margin-left:50px;
    }
  }
  