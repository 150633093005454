.historico-mensagens-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: 560px; /* Margem lateral esquerda para desktop */
    margin-bottom: 100px;
    gap: 20px;
  }
  .historico-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 15%; /* Ajusta o tamanho da imagem para caber no container */
    height: auto; /* Mantém a proporção da imagem */
    margin-bottom: 10px; /* Espaçamento abaixo da imagem */
    margin-top:20px;
}
  
  .historico-mensagens-item {
    width: calc(50% - 10px); /* Largura de 50% menos a metade do gap */
    max-width: 580px;
    height: 600px;
    padding: 20px;
    border: 4px solid #0c2746; /* Cor da borda atualizada */
  border-radius: 10px; /* Aumentado o border radius */
    overflow: hidden; /* Remover scroll do componente mais interno */
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza os itens horizontalmente */
  }
  
  .historico-mensagens-item select {
    width: 80%; /* Largura do dropdown menor */
    max-width: 300px; /* Largura máxima do dropdown */
    margin-bottom: 20px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .message-container {
    height: calc(100% - 60px); /* Ajuste para evitar scroll interno */
    overflow-y: auto;
    width: 100%; /* Garantir que a largura seja 100% */
  }
  
  .message {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    background-color: #e0f7fa;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .message.bot {
    background-color: #b0eaf3;
    text-align: left;
  }
  
  .message.user {
    background-color: #e8f5e9;
    text-align: right;
  }
  .message.atendente {
    background-color: #d1e7dd;
    text-align: left;
  }
  .message span {
    display: block;
    font-size: 0.8em;
    color: #888;
  }
  @media (max-width: 1968px) {

    .historico-mensagens-container {
      margin-left: 350px;
    }
  }
  @media (max-width: 1768px) {

    .historico-mensagens-container {
      margin-left: 250px;
    }
  }
  @media (max-width: 1468px) {
    .historico-mensagens-container {
      margin-left: 400px;
    }
  }
  @media (max-width: 1168px) {
    .historico-mensagens-container {
      margin-left: 100px;
    }
  }

  
  @media (max-width: 768px) {
    .historico-mensagens-container {
      margin-left: 60px; /* Margem lateral esquerda para dispositivos móveis */
      flex-direction: column;
      align-items: center;
    }
  
    .historico-mensagens-item {
      width: calc(100% - 40px); /* Ajustar a largura para dispositivos móveis */
      max-width: 100%;
      height: auto;
      padding: 15px;
    }
    .historico-image {
      
      max-width: 50%; /* Ajusta o tamanho da imagem para caber no container */
      
  }
  }
  