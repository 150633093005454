/* styles/ServicoIndisponivel.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.servico-indisponivel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.servico-indisponivel-content {
  width: 600px;
  max-width: 90%;
  height: 400px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
@media (max-width: 600px) {
    .servico-indisponivel-content {
      max-width: 300px;
      margin-left: 55px;
      margin-top: 0px;
    }
  }
.servico-indisponivel-content h2 {
  font-size: 1.5rem;
  color: #0c2746;
  margin-bottom: 20px;
}

.servico-indisponivel-content p {
  font-size: 1rem;
  color: #333;
}
.servico-indisponivel-img{
    margin-top:50px;
    width: 200px;
    height: 200px;
}